import { useEffect, useState } from "react";
import axios from "axios";

function useFetchHook(url, key) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const access_token = localStorage.getItem("access_token");
  const fetchData = () => {
    setLoading(true);
    axios.defaults.headers.common = {
      "access-control-allow-origin": "*",
      "trn-api-key": "123123",
      "content-type": "application/json",
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(`https://server.hirein.mosulcode.com/${url}`)
      //.get(`http://192.168.171.162:8000/${url}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  const refetchData = () => {
    fetchData();
  };

  return { data, loading, error, refetchData };
}

export default useFetchHook;
